<template>

    <div>

        <b-navbar id="navbar" toggleable="md" variant="light">

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-brand href="/">The Tracker</b-navbar-brand>
            </b-collapse>


            <!--            <b-button variant="outline-secondary" @click="onRequestChanged">Button</b-button>-->

            <b-input-group class="flex-nowrap text-nowrap w-auto nav-item">

                <b-input-group-prepend>
                    <b-button variant="outline-secondary" v-on:click="shiftDate(-1)" class="text-dark">
                        <b-icon icon="arrow-left-short"/>
                    </b-button>
                </b-input-group-prepend>

                <b-datepicker v-model="currentDate" hide-header today-button value-as-date
                              @input="onDateChanged"
                              :max="new Date()" right slot="default"
                              class="pt-1"
                              button-content="ariaDescribedby"
                              :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }">
                </b-datepicker>


                <b-input-group-append>
                    <b-button variant="outline-secondary" v-on:click="shiftDate(1)" class="text-dark">
                        <b-icon icon="arrow-right-short"/>
                    </b-button>
                </b-input-group-append>

            </b-input-group>


            <b-dropdown right variant="light" class="nav-item">
                <!-- Using 'button-content' slot -->
                <template #button-content>
                    <b-avatar size="sm"></b-avatar>
                </template>
                <b-dropdown-header>{{ currentuser.name }}</b-dropdown-header>
                <b-dropdown-item v-b-modal.modal-no-backdrop>Profile</b-dropdown-item>
                <b-dropdown-item href="/logout">Sign Out</b-dropdown-item>
            </b-dropdown>


        </b-navbar>

        <b-modal id="modal-no-backdrop"
                 hide-backdrop
                 content-class="shadow"
                 title-size="lg"
                 :title="currentuser.name"
                 cancel-variant="light">

            {{ currentuser.email }}
        </b-modal>

    </div>
</template>

<script>


export default {

    data() {
        return {
            currentDate: new Date(),
            options: [
                {value: 1, text: 'Pavel Malinnikov (me)'},
                {value: 2, text: 'Ken Malinnikov'},
                {value: 3, text: 'Yulia Malinnikova'},
            ],
            selected: "",
            color: 'var(--red)'
        }
    },

    props: ['currentuser'],

    methods: {

        changeItem: function (option) {

            this.selected = option
            console.log(option.value);
        },

        onDateChanged: function () {
            this.onRequestChanged();
        },

        shiftDate: function (days) {

            const newDate = new Date(this.currentDate)
            newDate.setDate(newDate.getDate() + days);
            this.currentDate = newDate;
            this.onRequestChanged();
        },

        onColorChange: function (color) {

            color = /var\((.+)\)/.test(color) ? RegExp.$1 : '?';
            console.log(getComputedStyle(document.documentElement).getPropertyValue(
                color
            ));
        },

        onRequestChanged: function () {

            this.$emit('onRequestChanged', {
                startDate: this.currentDate,
                endDate: this.currentDate,
                user: this.currentuser.id,
            });
        },

    },

    mounted() {
        this.selected = this.options[0]
    }
}


</script>

<style>

#navbar {
    /*height: 40pt;*/
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}

.nav-item {
    margin-left: 4pt;
    margin-right: 4pt;
}
</style>
